<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span
            :style="{color: formattedItem.avatarTextColor}"
            class="font-weight-bold subtitle-1"
          >{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
    <v-list-item-action>
      {{ formattedItem.importeTotal }}
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { DEUDA_CLIENTE } from '@/utils/consts'
import { fontColor } from '@/utils/ui'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      DEUDA_CLIENTE
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.facturacli.title(item)
      item.subtitle = this.$online.facturacli.subtitle(item)
      let estado = DEUDA_CLIENTE.idestados[item.estado_deuda]
      item.importeTotal = this.$options.filters.currency(item.importe_total)
      item.avatar = this.$options.filters.initials(DEUDA_CLIENTE.descripciones[estado])
      item.avatarColor = DEUDA_CLIENTE.colores[estado]
      item.avatarTextColor = fontColor(DEUDA_CLIENTE.colores[estado])
      return item
    }
  }
}
</script>
